<template>
    <v-container fluid style="padding-bottom: 50px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building" clearable dense solo v-model="region"  :items="regions" item-value="region" :item-text="item => item.region"
                                        label="Region" class="ma-0 pa-0 border-12" hide-details=true @change="updateOffice()" :disabled="region_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building-outline" clearable dense solo v-model="mill_id" :items="mills" item-value="office_id"
                                        :item-text="item => item.office.trim()" label="Office" class="ma-0 pa-0 border-12" hide-details=true :disabled="office_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-shape-plus" clearable dense solo v-model="cat_group" :items="categorygroups" item-title="cat_group" :item-text="item => item.cat_group.trim()" item-value="cat_group"
                                        label="Category Group" class="ma-0 pa-0 border-12" hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete 
                                        prepend-inner-icon="mdi-shape" clearable dense solo v-model="cat_desc" :items="categories" item-title="cat_desc" :item-text="item => item.cat_desc.trim()" item-value="cat_desc"
                                        label="Category" class="ma-0 pa-0 border-12" hide-details=true></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete 
                                        prepend-inner-icon="mdi-truck-fast" clearable dense solo v-model="FASTorSLOW" :items="sloworfast" item-value="value" :item-text="item => item.text" 
                                        label="Fast or Slow" class="ma-0 pa-0 border-12" hide-details=true></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete 
                                        prepend-inner-icon="mdi-image-text" clearable dense solo v-model="KetStok" :items="ketstok" item-value="value" :item-text="item => item.text" 
                                        label="Keterangan Stok" class="ma-0 pa-0 border-12" hide-details=true></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete 
                                        prepend-inner-icon="mdi-warehouse" clearable dense solo v-model="StokAvail" :items="stokavail" item-value="value" :item-text="item => item.text" 
                                        label="Available Stock" class="ma-0 pa-0 border-12" hide-details=true></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="6">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-3">
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <div id="container2" class="text-center">
                            <!-- <span class="font-weight-bold mt-10 text-uppercase">
                                Major Defect for Machine Type {{ machineType_field }} from {{ start_period_field }} to {{ end_period_field }}:
                                <template v-for="(label, index) in labels">
                                    <span v-if="index === labels.length - 1">
                                        "{{ label }}"
                                    </span>
                                    <span v-else>
                                        "{{ label }}" and
                                    </span>
                                </template>
                            </span> -->
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="12">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    class="elevation-1"
                                    :items="histories"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItemTrans" 
                                    :item-class="tr_datatable"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Stock Moving </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-btn
                                                small
                                                color="#005c37"
                                                class="py-5 mr-3 text-white border-12"
                                                >
                                                    <v-icon>mdi-file-excel</v-icon>
                                                    <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :data="histori_exports"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                    </download-excel>
                                                </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:item.KetStok="{ item }">
                                        <v-chip :class="getKetStokTextColor(item.KetStok)">{{ item.KetStok }}</v-chip>
                                    </template>
                                    <template v-slot:[`item.panjang`]="{ item }">
                                        {{ item.panjang | twoDecimals }}
                                    </template>
                                    <template v-slot:[`item.berat`]="{ item }">
                                        {{ item.berat | twoDecimals }}
                                    </template>
                                    <template v-slot:[`item.total6bulan`]="{ item }">
                                        {{ item.total6bulan | twoDecimals }}
                                    </template>
                                    <template v-slot:[`item.rata6bulan`]="{ item }">
                                        {{ item.rata6bulan | twoDecimals }}
                                    </template>
                                    <template v-slot:[`item.OST`]="{ item }">
                                        {{ item.OST | twoDecimals }}
                                    </template>
                                    <template v-slot:[`item.available`]="{ item }">
                                        {{ item.available | twoDecimals }}
                                    </template>
                                    <template v-slot:[`item.STOK`]="{ item }">
                                        {{ item.STOK | twoDecimals }}
                                    </template>
                                    <template v-slot:[`item.minStok`]="{ item }">
                                        {{ item.minStok | twoDecimals }}
                                    </template>
                                    <template v-slot:[`item.maxStok`]="{ item }">
                                        {{ item.maxStok | twoDecimals }}
                                    </template>
                                    <template v-slot:[`item.STOKKG`]="{ item }">
                                        {{ item.STOKKG | noDecimals }}
                                    </template>
                                    <template v-slot:[`item.UMURSTOK`]="{ item }">
                                        {{ item.UMURSTOK | oneDecimals }}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            modal: false,
            loading: false,
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            searchItemTrans: '',
            division: 'all',
            sloworfast: [
                'SLOW MOVING', 'NORMAL', 'FAST MOVING'
            ],
            ketstok: [
                'KURANG', 'CUKUP', 'OVER', 'DEATH STOCK'
            ],
            stokavail: [
                'AVAILABLE', 'UNAVAILABLE'
            ],
            division_disabled: false,
            region_proyeks:[],
            region_proyek_disabled: false,
            region_proyek: '',
            regions: [],
            histories: [],
            summaries: [],
            mill_id: '',
            FASTorSLOW: '',
            KetStok: '',
            region: '',
            offices: [],
            mills: [],
            cat_group: '',
            cat_desc: '',
            category_id: '',
            categorygroups: [],
            categories: [],
            loading_office: false,
            office_disabled: false,
            office: '',
            histori_exports: [],
            region_disabled: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'KMB',
                    disabled: false,
                    href: '/admin/kmb',
                },
                {
                    text: 'E-Reports',
                    disabled: true,
                },
                {
                    text: 'Stock Moving',
                    disabled: true,
                }
            ],
            headers: [
                // { text: 'Production Code', value: 'prod_code', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Nama Barang', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Panjang', value: 'panjang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Berat', value: 'berat', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Total 6 Bulan', value: 'total6bulan', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Rata 6 bulan', value: 'rata6bulan', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'OST', value: 'OST', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Delivering', value: 'delivering', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Available', value: 'available', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Stok', value: 'STOK', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Min Stok', value: 'minStok', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Max Stok', value: 'maxStok', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Stok KG', value: 'STOKKG', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Umur Stok', value: 'UMURSTOK', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Add ID', value: 'add_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Tor ID', value: 'tor_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Fast or Slow', value: 'FASTorSLOW', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Ket Stok', value: 'KetStok', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Office', value: 'office', align: 'left', class: 'primary--text blue lighten-5' }
            ],
        }
    },
    filters: {
        twoDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(2);
        },
        oneDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(1);
        },
        noDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(0);
        },
    },
    computed:{
    },
    watch: {
        
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getEnvConf()
        await this.getCategory()
        await this.getCategoryGroup()

        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        getKetStokTextColor(KetStok) {
            if (KetStok === 'DEATH STOCK') {
                return 'red'; 
            } else if (KetStok === 'KURANG') {
                return 'yellow'; 
            } else if (KetStok === 'CUKUP') {
                return 'green'; 
            } else if (KetStok === 'OVER') {
                return 'blue'; 
            } else {
                return '';
            }
        },
        tr_datatable(item) {
            var rowClass = 'tr_datatable_history'
            return rowClass;
        },
        async getEnvConf(){
            if (this.$store.state.kmb.group_id != 'RM PROJECT') {
                const respDataRegion = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=REGION&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)  
                
                if (respDataRegion.status == 200) {
                    this.region = respDataRegion.data.data ? respDataRegion.data.data : ''
                    this.region_disabled = respDataRegion.data.data ? true : false
                }
                
            }

            const respDataOffice = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=BRANCH&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)  

            if (respDataOffice.status == 200) {
                this.mill_id = respDataOffice.data.data ? respDataOffice.data.data : ''
                this.office_disabled = respDataOffice.data.data ? true : false
                if (this.office_disabled) {
                    this.region_disabled = true
                    await this.getRegion()
                    await this.getOffice()
                } else {
                    await this.getOffice('KMB')
                }
            }
            console.log(this.region)
            console.log(this.mill_id)
            await this.getRegion()
            await this.getOffice()
        },
        clear(){
            if (this.region_disabled == false) {
                this.region = ''
            }
            if (this.office_disabled == false) {
                this.mill_id = ''
            }
            this.category_id = ''
            this.cat_group = ''
            this.FASTorSLOW = ''
            this.KetStok = ''
            this.StokAvail = ''
        },
        async exportExcel(){
            if (this.region === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Region',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)  
                return false
            }

            var reqBody = {
                'region': this.region ? this.region : '',
                'mill_id': this.mill_id ? this.mill_id : '',
                'cat_group': this.cat_group ? this.cat_group : '',
                'cat_desc': this.cat_desc ? this.cat_desc : '',
                'FASTorSLOW': this.FASTorSLOW ? this.FASTorSLOW : '',
                'KetStok': this.KetStok ? this.KetStok : '',
                'StokAvail': this.StokAvail ? this.StokAvail : '',
            }

            const respData = await backendApi.fetchCore('/api/v3/kmb/stock', reqBody, false, false, false)
            console.log("respdata", respData)
            console.log("respdata.data", respData.data)
            if (respData.status === 200) {
                this.histori_exports = respData.data
                // this.$store.dispatch('setOverlay', false)  
                return this.histori_exports
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
        async updateOffice() {
            await this.getOffice();
            console.log("check Office",this.getOffice());
        },
        async getRegion(){

            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/stock/region`, {
                region: this.region,
                mill_id: this.mill_id,
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                }
            }).then(res => {
                console.log(res)
                this.regions = res.data.result
            });
        },
        async getOffice(){
            this.loading_office = true
            this.mills = []

            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/stock/office`, {
                region: this.region,
                mill_id: this.mill_id,
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                }
            }).then(res => {
                this.loading_office = false
                this.mills = res.data
            });
        },
        async getCategoryGroup(){
            this.categorygroups = []

            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/stock/catGroup`, {
                cat_group: this.cat_group,
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                }
            }).then(res => {
                this.categorygroups = res.data
            });
        },
        async getCategory(){
            this.categories = []

            await axios.post(`${process.env.VUE_APP_URL}/api/v3/kmb/stock/cat`, {
                category_id: this.category_id,
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                }
            }).then(res => {
                this.categories = res.data
            });
        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true)  

            if (this.region === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Region',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)  
                return false
            }
            
            var reqBody = {
                'region': this.region ? this.region : '',
                'mill_id': this.mill_id ? this.mill_id : '',
                'cat_group': this.cat_group ? this.cat_group : '',
                'cat_desc': this.cat_desc ? this.cat_desc : '',
                'FASTorSLOW': this.FASTorSLOW ? this.FASTorSLOW : '',
                'KetStok': this.KetStok ? this.KetStok : '',
                'StokAvail': this.StokAvail ? this.StokAvail : '',
            }

            const respData = await backendApi.fetchCore('/api/v3/kmb/stock', reqBody, false, false, false);
            if (respData.status === 200) {
                console.log("API Response Data:", respData.data); 
                if (Array.isArray(respData.data)) {
                    this.histories = respData.data; 
                } else {
                    console.error("Expected an array but got:", typeof respData.data);
                    this.histories = []; 
                }
                // this.$store.dispatch('setOverlay', false);
                // this.summary();
                console.log("Histories:", this.histories); 
            }
            const respData2 = await backendApi.fetchCore('/api/v3/kmb/stock/summary', reqBody, false, false, false);
            if (respData2.status === 200) {
                console.log("API Response Data:", respData2.data);
                if (Array.isArray(respData2.data) && respData2.data.length > 0) {
                    this.summaries = respData2.data;

                    const summary = respData2.data[0];
                    const totalStokKg = summary.total_stokkg || 0;
                    const totalFastMovingStokKg = summary.total_fast_moving_stokkg || 0;
                    const totalSlowMovingStokKg = summary.total_slow_moving_stokkg || 0;
                    const PercentageStokKg = totalStokKg / totalStokKg * 100 || 0;
                    const PercentageFastMovingStokKg = totalFastMovingStokKg / totalStokKg * 100 || 0;
                    const PercentageLowMovingStokKg = totalSlowMovingStokKg / totalStokKg * 100 || 0;

                    const container2 = document.getElementById('container2');
                    if (container2) {
                                container2.innerHTML = `
                                    <p><strong>Total Stock (Kg): ${Math.round(totalStokKg).toLocaleString()} - ${Math.round(PercentageStokKg.toFixed(3))}%</strong></p>
                                    <p><strong>Total Fast Moving Stock (Kg): ${Math.round(totalFastMovingStokKg).toLocaleString()} - ${(PercentageFastMovingStokKg.toFixed(3))}%</strong></p>
                                    <p><strong>Total Slow Moving Stock (Kg): ${Math.round(totalSlowMovingStokKg).toLocaleString()} - ${(PercentageLowMovingStokKg.toFixed(3))}%</strong></p>
                                `;
                            }

                    console.log("Summaries:", this.summaries);
                } else {
                    console.error("Expected a non-empty array but got:", respData2.data);
                    this.summaries = [];
                }

                this.$store.dispatch('setOverlay', false);
            }
        },
    },    
}
</script>